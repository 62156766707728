
import {
  computed,
  onMounted,
  watch,
  defineComponent,
  ref,
  handleError,
} from "vue";
import { Form, useForm } from "vee-validate";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment/moment";
import { Modal } from "bootstrap";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import TableExplorer from "@/components/TableExplorer.vue";

interface TransportData {
  name: string;
  cuit: string;
}

interface RawFile {
  name: string;
  url: string;
  hash: string;
}

type Location = {
  lat: number | null;
  lng: number | null;
};

export default defineComponent({
  name: "new-donor-form",
  props: {
    endpoint: {
      type: String,
      default: "donors",
    },
    title: {
      type: String,
      default: "Formulario de Datos",
    },
    showRequired: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Array,
      default: () => {
        return ["type"];
      },
    },
  },
  components: {
    TableExplorer,
    ElectronicSignature,
    // GoogleMap,
    // Marker,
    Form,
  },
  data() {
    return {
      rejectionRequestReasons: {},
      isReactivationMode: false,
      isCorrectionMode: false,
      correctioType: "",
      correctFormData: {
        correctableId: "",
        correctableType: "",
        reasons: [],
        detail: "",
      },
      showNear: false,
      startDate: moment(),
      endDate: moment(),
      baseDate: moment().format("DD/MM/YYYY"),
      showGeoWarning: false,
      donorUntil: "",
      showDuplicates: false,
      showDuplicatesBreak: false,
      showDuplicatesBreakDNI: false,
      nearFilters: {},
      loadingDuplicates: false,
      duplicates: [],
      duplicatesBreak: [],
      duplicatesBreakDNI: [],
      inputValue: "",
      mapStyle: [
        {
          featureType: "transit",
          stylers: [
            {
              color: "#808080",
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      geolocateLoading: false,
      loading: false,
      visitorList: [],
      assignPerms: {
        visitor: false,
        collector: false,
        transport: false,
        schedule: false,
        edit: false,
      },
      imageAction: "",
      editMode: false,
      localityList: [],
      stateList: [],
      scheduleList: {
        "0": "Lunes, miércoles y viernes",
        "1": "Martes, jueves y sábados",
      },
      transportList: [],
      collectorList: [],
      criticalError: null,
      signatureType: "update",
      zoomLevel: 18,
      mode: "donors",
      requireReason: false,
      requireExtra: true,
      errors: {},
      correctErrors: {},
      entity: {
        id: "",
        valid: {
          errors: [],
        },
        status: "",
        type: "",
        firstName: "",
        lastName: "",
        transportId: "",
        mapNumber: "",
        mapGrid: "",
        scheduleId: "",
        booklet: false,
        filePaper: false,
        answeredQuestions: false,
        registrationDate: "",
        electronicSignature: "",
        workdayId: "",
        crossStreet1: "",
        crossStreet2: "",
        addressReference: "",
        isGeoLocated: false,
        birthdate: "",
        addressImage: ref<RawFile[]>([]),
        fileImage: ref<RawFile[]>([]),
        fum: "",
        latitude: "",
        longitude: "",
        stateId: "",
        localityId: "",
        dni: "",
        email: "",
        address: "",
        address_number: "",
        address_apt: "",
        address_floor: "",
        phone: "",
        phone_2: "",
        neighborhood: "",
        dniNull: false,
        addressNumberNull: false,
        dniRaw: "",
        preloadDraftId: "",
      },
    };
  },
  setup() {
    const store = useStore();
    const location = ref<Location | null>({ lat: null, lng: null });
    const entityLoc = computed({
      get: () => location,
      set: (value) => {
        location.value = value.value;
      },
    });

    return { entityLoc, store, location };
  },
  computed: {
    getDefaultFUM() {
      return moment().subtract(105, "days").toDate();
    },
  },
  methods: {
    handleRefreshTransports(e) {
      this.refreshTransports();
    },
    updateDonorUntil(value) {
      let date = moment(value, "DD-MM-YYYY");
      this.donorUntil = date.add(135, "days").format("DD/MM/YYYY");
    },
    checkDate(date) {
      let m = moment(date);
      let dateFrom = moment(this.baseDate, "DD/MM/YYYY").subtract(105, "days");
      let dateTo = moment(this.baseDate, "DD/MM/YYYY").subtract(30, "days");
      return !(
        m.isSameOrAfter(dateFrom, "day") && m.isSameOrBefore(dateTo, "day")
      );
    },
    checkDuplicatesBreakDNI() {
      if (
        this.entity.dni.trim() != ""
      ) {
        this.showDuplicatesBreakDNI = true;
        this.loadingDuplicates = true;
        ApiService.query("mobile/donors/duplicate", {
          params: {
            donorId: this.entity.id ?? null,
            dni: this.entity.dni.replace(/\D/g, ''),
          },
        }).then((result) => {
          this.loadingDuplicates = false;
          this.duplicatesBreakDNI = result.data.data.duplicateDonors;
        });
      } else {
        this.showDuplicatesBreakDNI = false;
        this.duplicatesBreakDNI = [];
      }
    },
    checkDuplicatesBreak() {
      if (
        this.entity.birthdate.trim() != "" &&
        this.entity.firstName.trim() != "" &&
        this.entity.lastName.trim() != "" &&
        this.entity.phone.trim() != ""
      ) {
        this.showDuplicatesBreak = true;
        this.loadingDuplicates = true;
        ApiService.query("mobile/donors/duplicate", {
          params: {
            donorId: this.entity.id ?? null,
            name: this.entity.firstName,
            lastName: this.entity.lastName,
            birthDate: this.entity.birthdate,
            phone: this.entity.phone,
          },
        }).then((result) => {
          this.loadingDuplicates = false;
          this.duplicatesBreak = result.data.data.duplicateDonors;
        });
      } else {
        this.showDuplicatesBreak = false;
        this.duplicatesBreak = [];
      }
    },
    checkDuplicates() {
      if (
        this.entity.birthdate.trim() != "" &&
        this.entity.firstName.trim() != "" &&
        this.entity.lastName.trim() != ""
      ) {
        this.showDuplicates = true;
        this.loadingDuplicates = true;
        ApiService.query("mobile/donors/duplicate", {
          params: {
            donorId: this.entity.id ?? null,
            name: this.entity.firstName,
            lastName: this.entity.lastName,
            birthDate: this.entity.birthdate,
          },
        }).then((result) => {
          this.loadingDuplicates = false;
          this.duplicates = result.data.data.duplicateDonors;
        });
      } else {
        this.showDuplicates = false;
        this.duplicates = [];
      }
    },
    changeFileImage(data) {
      if (data.status === "success") {
        this.entity.fileImage.push({
          name: data.name,
          url: data.url,
          hash: data.response.data.hash,
        });
      }
    },
    removeFileImage(data, fileList) {
      this.entity.fileImage = fileList;
    },
    changeAddressImage(data) {
      if (data.status === "success") {
        this.entity.addressImage.push({
          name: data.name,
          url: data.url,
          hash: data.response.data.hash,
        });
      }
    },
    removeAddressImage(data, fileList) {
      this.entity.addressImage = fileList;
    },
    processImage(data) {
      var formData = new FormData();
      formData.append("file", data.file);
      formData.append("type", data.data.type);
      ApiService.post("upload", formData)
        .then(data.onSuccess)
        .catch(data.onError);
    },
    updateCoordinates(event) {
      this.entityLoc.value = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      } as Location;
      this.entity.isGeoLocated = true;
    },

    dniNullChanged() {
      if (this.entity.dniNull) {
        this.entity.dni = "";
      }
    },

    addressNumberNullChanged() {
      if (this.entity.addressNumberNull) {
        this.entity.address_number = "";
      }
    },
    geoCode() {
      ApiService.query("reverse", { params: this.entity }).then((result) => {
        if (result.data.geocode.address) {
          this.entity.address = result.data.geocode.address;
        }

        if (result.data.geocode.stateId) {
          this.entity.stateId = result.data.geocode.stateId.toString();
        }
        
        this.refreshLocalities();
        
        if (result.data.geocode.address_number) {
          this.entity.address_number = result.data.geocode.address_number;
        }
        
        if (result.data.geocode.localityId) {
          this.entity.localityId = result.data.geocode.localityId.toString();
        }
        
        if (result.data.geocode.geometry) {
          this.entityLoc.value = {
            lat: result.data.geocode.geometry.lat,
            lng: result.data.geocode.geometry.lng,
          } as Location;
          this.entity.isGeoLocated = true;
        }
      });
    },

    async getAddressData(addressData) {
      let result = await ApiService.query("parsegeo", {
        params: {
          address_components: addressData.address_components,
          geometry: addressData.geometry,
        },
      });

      this.inputValue = "";

      this.entity.address = result.data.geocode.address;
      this.entity.address_number = result.data.geocode.address_number;
      this.entity.neighborhood = result.data.geocode.neighborhood;

      if (result.data.geocode.stateId != null) {
        this.entity.stateId = result.data.geocode.stateId.toString();
        this.refreshLocalities();
      }

      if (result.data.geocode.localityId != null) {
        this.entity.localityId = result.data.geocode.localityId.toString();
      }

      if (result.data.geometry != null) {
        this.entityLoc.value = {
          lat: result.data.geometry.lat,
          lng: result.data.geometry.lng,
        } as Location;
        this.entity.isGeoLocated = true;
      }

      if (result.data.geocode.intersection != undefined) {
        this.entity.crossStreet1 = result.data.geocode.intersection;
      }


      return false;
    },
    refreshGeocoding() {
      if (this.entityLoc.value) {
        ApiService.query("geo", {
          params: {
            lat: this.entityLoc.value.lat,
            lng: this.entityLoc.value.lng,
          },
        }).then((result) => {
          this.entity.address = result.data.geocode.address;
          this.entity.address_number = result.data.geocode.address_number;

          if (result.data.geocode.stateId != null) {
            this.entity.stateId = result.data.geocode.stateId.toString();
            this.refreshLocalities();
          }

          if (result.data.geocode.localityId != null) {
            this.entity.localityId = result.data.geocode.localityId.toString();
          }

          this.entity.isGeoLocated = true;
        });
      }
    },

    useCurrentLocation() {
      this.geolocateLoading = true;

      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.entityLoc.value = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          } as Location;
          this.entity.isGeoLocated = true;
          this.refreshGeocoding();
        },
        (error) => {
          Swal.fire({
            title: "Error al geolocalizar",
            text: "No se pudo obtener la ubicación de su dispositivo. Compruebe que se encuentre habilitada.",
            icon: "error",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      );

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const geocoding = require("geocoding");
    },
    catchErrors(error) {
      if (this.isCorrectionMode) {
        const modal = Modal.getInstance(
          document.getElementById("correct-modal")
        );
        modal?.hide();
      }
      if (error.response.status == 451) {
        return this.triggerSignatureForm();
      }

      this.entity.electronicSignature = "";
      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    submitForm() {
      this.errors = {};
      if (
        this.entityLoc.value !== null &&
        this.entityLoc.value.lat !== null &&
        this.entityLoc.value.lng !== null
      ) {
        this.entity.latitude = this.entityLoc.value.lat.toString();
        this.entity.longitude = this.entityLoc.value.lng.toString();
      }

      if (this.isCorrectionMode) {
        this.toCorrect();
      } else {
        this.storeFormData();
      }

    },
    toCorrect() {
      var modalCorrect = new Modal(document.getElementById("correct-modal"));
      modalCorrect?.show();
    },
    triggerSignatureForm() {
      var modalito = new Modal(document.getElementById("sign-modal"));
      modalito.show();
    },
    storeFormDataWithSignature(signatureData) {
      this.entity.electronicSignature = signatureData.signature;
      this.storeFormData();
    },
    async storeFormData() {
      this.entity.dni = this.entity.dniRaw;
      if (this.entity.id) {
        return ApiService.update(
          this.mode,
          this.entity.id.toString(),
          this.entity
        )
          .then(() => {
            if (this.isReactivationMode) {
            ApiService.delete("donors/" + this.entity.id + "/suspension")
            .then((response) => {
              if (response.status === 202) {
                Swal.fire({
                  title: "Solicitud Generada",
                  text: "Se ha generado una solicitud de reactivación correctamente.",
                  icon: "info",
                  confirmButtonText: "Aceptar",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              }
            });
            }

            if (this.isCorrectionMode) {
              this.correctRequest();
            } else {
              this.$router.back();
            }
        })
          .catch(this.catchErrors);
      } else {
        return ApiService.post(this.mode, this.entity)
            .then((response) => {
            this.$router.push({ name: 'donor-table'});
          })
          .catch(this.catchErrors);
      }
    },
    selectedState() {
      this.entity.localityId = "";
      this.refreshLocalities();
    },
    selectedLocality() {
      // TODO: Something with locality
    },
    refreshLocalities() {
      ApiService.query("localities", {
        params: { stateId: this.entity.stateId },
      }).then((response) => {
        this.localityList = response.data;
      });
    },
    refreshCollectors() {
      ApiService.query("index/users", {
        params: {
          permission: "collect",
          transport_id: this.entity.transportId,
        },
      }).then((response) => {
        this.collectorList = response.data;
      });
    },
    retrieveCorrectReasons() {
      this.loading = true;
      ApiService.get("rejection_reasons_request").then((response) => {
        this.loading = false;
        this.rejectionRequestReasons = response.data.data;
      }).catch((error) => {
        console.error("Error fetching rejection_reasons_request data:", error);
        this.loading = false;
      });
    },
    correctRequest() {
      this.correctFormData.correctableId = this.entity.id;
      this.correctFormData.correctableType = this.correctioType;
      ApiService.post("correction_request", this.correctFormData)
        .then(() => {
          Swal.fire({
            title: "Solicitud Generada",
            text: "Se ha generado una solicitud de corrección correctamente.",
            icon: "info",
            confirmButtonText: "Aceptar",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            onClose: () => {
              const modal = Modal.getInstance(
                document.getElementById("correct-modal")
              );
              modal?.hide();
              this.$router.push({ name: 'mis_solicitudes'});
            }
          });
        })
        .catch((error) => {
          this.correctErrors = error.response.data.errors;
          Swal.fire({
            text: error.response?.data?.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Cerrar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    },
    refreshTransports() {
      ApiService.query("index/transports", {
        params: {
          selectable: true,
          force: this.entity.transportId ?? null,
          mapNumber: this.entity.mapNumber,
          mapGrid: this.entity.mapGrid,
        },
      }).then((response) => {
        this.transportList = response.data;
      });
    },

    searchNearBy() {
      this.showNear = true;
      this.nearFilters = {
        nearBy: this.entityLoc,
        type: this.entity.type,
        status: "active",
      };
      (this.$refs.nearByMap as any).retrieveData();
    },
  },
  mounted() {
    this.isReactivationMode = this.$route.query.reactivate === "true";
    this.isCorrectionMode = this.$route.query.correction === "true";
    this.correctioType = this.$route.query.correctionType as string;

    const store = useStore();

    watch(this.nearFilters, (variable) => {
      (this.$refs.donorTable as any).retrieveData();
    });

    this.assignPerms.edit = true;
    this.assignPerms.collector = false;
    this.assignPerms.schedule = true;
    this.assignPerms.transport = true;
    this.assignPerms.visitor = true;

    if (
      store.getters.getPermissions.includes("donor_create.hmg") &&
      !store.getters.getPermissions.includes("donor_create.hcg")
    ) {
      this.entity.type = "HMG";
    }

    if (
      !store.getters.getPermissions.includes("donor_create.hmg") &&
      store.getters.getPermissions.includes("donor_create.hcg")
    ) {
      this.entity.type = "HCG";
    }

    this.imageAction =
      ApiService.vueInstance.axios.defaults.baseURL + "/upload";

    ApiService.query("index/states", {
      params: { permission: "place_visit" },
    }).then((response) => {
      this.stateList = response.data;
    });

    this.refreshTransports();

    let endpoint =
      this.$route.params.workdayId || this.$route.params.preloadId
        ? "preloads"
        : "donors";

    this.entity.workdayId = this.$route.params.workdayId
      ? this.$route.params.workdayId.toString()
      : "";

    this.entity.preloadDraftId = this.$route.params.preloadDraftId as string;

    if (this.entity.preloadDraftId) {
      ApiService.get("preload_drafts", this.entity.preloadDraftId).then((response) => {
        this.entity.type = response.data.type;
        this.entity.firstName = response.data.firstName;
        this.entity.lastName = response.data.lastName;
        this.entity.fum = response.data.fum;
        this.entity.phone = response.data.phoneNumber;
       });
    }

    this.mode = endpoint;  

    if (this.$route.params.id || this.$route.params.preloadId) {
      this.loading = true;
      let id = this.$route.params.id
        ? this.$route.params.id
        : this.$route.params.preloadId;
      this.signatureType = "update";

      this.editMode = true;

      if (this.$route.name == "donor-reactivate") {
        this.entity.status = "active";
        this.editMode = false;
      }

      if (this.$route.query.reactivate === "true") {
        this.editMode = false;

      }

      ApiService.get(endpoint, id.toString()).then((response) => {
        if (response.data.data) {
          response.data = response.data.data;
        }

        if (
          this.$route.name == "donor-reactivate" &&
          response.data.status != "inactive"
        ) {
          this.$router.push({
            name: "donor-entity",
            params: { id: response.data.id },
          });
        }

        if (response.data.location) {
          this.entityLoc.value = {
            lat: response.data.location.coordinates[1],
            lng: response.data.location.coordinates[0],
          };
        }
        this.entity.id = response.data.id;
        this.entity.type = response.data.type;
        this.entity.firstName = response.data.firstName;
        this.entity.dni = response.data.dni;
        this.entity.dniNull = response.data.dni === null;
        if (this.entity.dniNull) {
          (this.entity.address_number as any) = null;
        }
        this.entity.addressNumberNull = response.data.address_number === null;
        if (this.entity.addressNumberNull) {
          (this.entity.address_number as any) = null;
        }

        this.entity.fum = response.data.fum;
        this.updateDonorUntil(this.entity.fum);

        if (response.data.workday_id) {
          this.entity.workdayId = response.data.workday_id.toString();
        }

        if (response.data.transport_id) {
          this.entity.transportId = response.data.transport_id.toString();
        }

        if (response.data.schedule_id != null) {
          this.entity.scheduleId = response.data.schedule_id.toString();
        }

        if (response.data.locality) {
          this.entity.localityId = response.data.locality.id.toString();
          this.entity.stateId = response.data.locality.state.id.toString();
        }

        this.entity.answeredQuestions = response.data.answered_questions;
        this.entity.crossStreet1 = response.data.crossStreet1;
        this.entity.addressReference = response.data.addressReference;
        this.entity.booklet = response.data.booklet == 1;

        this.entity.filePaper = response.data.file_paper == 1;

        this.entity.isGeoLocated = response.data.isGeoLocated;
        this.entity.crossStreet2 = response.data.crossStreet2;

        this.entity.birthdate = response.data.birthdate;
        this.entity.registrationDate = response.data.registration_date;

        this.entity.lastName = response.data.lastName;
        this.entity.address = response.data.address;
        this.entity.address_apt = response.data.address_apt;
        this.entity.address_number = response.data.address_number;
        this.entity.address_floor = response.data.address_floor;
        this.entity.neighborhood = response.data.neighborhood;
        this.entity.mapNumber = response.data.map_number;
        this.entity.mapGrid = response.data.map_grid;
        this.entity.email = response.data.email;
        this.entity.phone = response.data.phone;
        this.entity.phone_2 = response.data.phone_2;
        this.refreshLocalities();
        this.refreshCollectors();
        if (endpoint == "preloads") {
          this.errors = response.data.valid.errors;
        }

        if (response.data.location == null) {
          this.geoCode();
          this.showGeoWarning = true;
        }

        if (this.entity.workdayId != "") {
          //this.useCurrentLocation();

          ApiService.get("workdays", this.entity.workdayId).then((res) => {
            this.baseDate = res.data.date.toString();
          });
        }

        this.loading = false;
      });
    } else {
      if (this.entity.workdayId != "") {
        this.useCurrentLocation();

        ApiService.get("workdays", this.entity.workdayId).then((res) => {
          this.baseDate = res.data.date.toString();
        });
      }

      this.assignPerms.collector = false;
      this.assignPerms.schedule = true;
      this.assignPerms.transport = true;
      this.assignPerms.visitor = true;

      this.signatureType = "create";
      this.requireReason = false;
      this.requireExtra = false;
    }

    if (this.isCorrectionMode) {
      this.retrieveCorrectReasons();
    }
  },
});
